.header {
  background-color: #20232a;
  color: #fff;
  min-height: 64px;
  z-index: 10;
  position: relative;

  .link {
    transition: background-color .3s;
    color: #fff;

    &:hover,
    &:focus {
      background-color: #014350;
      text-decoration: none;
    }
  }

  .bar-link {
    font-size: 1.3rem;
  }

  .logo-link {
    font-size: 1.3rem;
    line-height: 1.3rem;
    color: #fff;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    img {
      margin-right: 20px;
      height: 40px;
      border: 2px solid #04a1bf;
      padding: 5px;
      border-radius: 6px;
    }
  }

  .git-link {
    font-size: 1.7rem;
  }
}
