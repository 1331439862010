.navigation {
  background-color: #f7f7f7;
  border-right: 1px solid #ececec;

  a {
    color: #1a1a1a;

    &.active {
      color: #1976d2;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding-left: 20px;
      font-size: .9rem;
    }
  }
}
