.home {
  .short {
    // background-color: #282c34;
    // background: rgb(2,0,36);
    background: linear-gradient(0deg, #fdd204 0%, #fdd204 52%, #0359b7 52%, #0359b7 100%);
    color: #fff;

    @media (min-width: 768px) {
      background: linear-gradient(0deg, #fdd204 0%, #fdd204 45%, #0359b7 45%, #0359b7 100%);
    }
  }

  .logo {
    max-width: 150px;
  }

  .feature {
    width: 365px;
  }

  .icon {
    font-size: 4rem;
    color: #04a1bf;
  }

  h2 {
    font-size: 1.5rem;
    color: #333;
  }

  p {
    color: #666;
  }
}
