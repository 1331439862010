/* You can add global styles to this file, and also import other style files */

// $fa-font-path: "node_modules/@fortawesome/fontawesome-free/webfonts";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

$container-max-widths: (
  sm: 768px,
  md: 920px,
  lg: 1100px,
  xl: 1500px
);

@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/badge";

html, body {
  height: 100%;
}

.doc-title {
  font-size: 1.7rem;
  font-weight: 500;
  color: #333;
}

.doc-subtitle {
	font-weight: 500;
	color: #333;
}

h2.doc-subtitle {
  font-size: 1.4rem;
}

h3.doc-subtitle {
  font-size: 1.1rem;
}

.demo {
  max-width: 900px;
}

.prism-code {
  padding: .5rem;
  margin: 0;
}

.my-cron [tab-name="SECONDS"] {
  .c-and {
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 5px;

    &-item[item-value="1"],
    &-item[item-value="20"] {
      .c-and-item-check {
        background-color: red;
        border-radius: 5px;
        color: #fff;
        padding-left: 1.5rem;
      }
    }
  }
}

.my-vertical-cron .c-host {
  display: flex;
  flex-direction: row;

  .c-tabs {
    flex-direction: column;
    border: 0;
    border-right: 1px solid #ccc;

    .c-tab {
      text-align: left;
      border: 0;
      border-radius: 0;
    }
  }

  .c-tab-content {
    padding-top: .5rem;
    padding-left: 1rem;
  }
}

$prefix: '.my';

#{$prefix}-row {
  overflow: hidden;

  #{$prefix}-col-1 {
    width: calc(100% / 12);
    float: left;
  }

  #{$prefix}-col-2 {
    width: calc(100% / 6);
    float: left;
  }
}

#{$prefix}-form-inline {
  display: flex;
  flex-direction: row;
}

#{$prefix}-form-control {
  margin: 0 .2rem;
}

#{$prefix}-form-check-label {
  padding-left: .4rem;
}

.help {
  background-color: #444;
  padding: 10px 20px;
  text-align: center;

  img {
    width: 26px;
    vertical-align: bottom;
    margin: 0 10px;
  }

  a {
    color: #bae0ff;
    text-decoration: none;

    span {
      color: #fff;
    }
  }
}
